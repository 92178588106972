type TMaxBreakPoints = {
  mobile: string;
  mobilePortrait: string;
  tablet: string;
  tabletPortrait: string;
  smallDesktop: string;
  largeDesktop: string;
};

const maxBreakPoints: TMaxBreakPoints = {
  mobile: '480px',
  mobilePortrait: '844px',
  tablet: '1024px', // 992?
  tabletPortrait: '1194px', // 992?
  smallDesktop: '1280px',
  largeDesktop: '1440px',
};

export default maxBreakPoints;
