import Color from 'color';

export { default as colors } from './colors';
export { default as mixins } from './mixins';
export { default as gradients } from './gradients';
export { default as breakPoints } from './breakPoints';

export const toRgba = (color: string, alpha: number) => Color(color).alpha(alpha).string();

export const lighten = (color: string, pct: number) => Color(color).lighten(pct).string();

export const darken = (color: string, pct: number) => Color(color).darken(pct).string();
