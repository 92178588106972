export type TGradients = 'purple' | 'green' | 'brown' | 'blue' | 'yellow';

type ColorsType = {
  [key: string]: string;
};

const gradients: ColorsType = {
  purple: 'linear-gradient(90deg, #F87A7A 0%, #F9947D 20%, #F57AB1 40%, #E07DE8 60%, #DBB2ED 80%, #EFB4C9 100%);',
  green: 'linear-gradient(90deg, #43C0AC 0%, #88EBBF 25%, #C4F9BD 50%, #FBFFAB 75%, #FFFFD8 100%);',
  brown: 'linear-gradient(90deg, #571D18 0%, #7D3924 25%, #B76434 50%, #D38658 75%, #FEDEC7 100%);',
  blue: 'linear-gradient(90deg, #002BA3 0%, rgba(36, 90, 240, 0) 100%);',
  yellow: 'linear-gradient(90deg, #FFC940 0%, rgba(255, 217, 121, 0) 100%);',
};

export default gradients;
